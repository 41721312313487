import React from "react"
import Hero from "../components/book-appt/hero-new-patient-intake"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import NewReferralForm from "../components/book-appt/new_referral_form"
import { graphql } from "gatsby"

const BookAppointmentPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Home" />
      <Hero />
      <NewReferralForm data={data.contentfulBookAnAppointmentPage} />
    </Layout>
  )
}

export default BookAppointmentPage

export const query = graphql`
  {
    contentfulBookAnAppointmentPage {
      referralLinks {
        id
        title
        content {
          content
        }
        url
      }
      referralSlider {
        id
        testimonial {
          testimonial
        }
        client
      }
    }
  }
`
