import React, { useState } from "react"
import Select from "react-select"
import ReferralLinks from "../shared/referral_links"
import ReCAPTCHA from "react-google-recaptcha"

const customStyles = {
  control: (base, state) => ({
    ...base,
    // background: "transparent",
    border: "2px solid #fff",
    color: "#000",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#ff0245" : "#ff0245",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#ff0245" : "#ff0245",
      boxShadow: state.isFocused ? null : null,
      color: state.isFocused ? "brown" : "grey",
    },
  }),
  menu: (base, state) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    border: "1px solid #ff0245",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    color: "#262b4a",
    color: state.isFocused ? "orange" : "#ff0245",
  }),
}

const ReferralForm = ({ data }) => {
  const [capVal, setCapVal] = useState()
  const [values, setValues] = useState({
    ownerFName: "",
    ownerLName: "",
    email: "",
    mobilePhone: "",
    workPhone: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    employer: "",
    authEmergencyDecisions: "",
    howHearAboutUs: "",
    dOBirth: "",
    occupation: "",
    authorizeEmergencyContactPatientInfo: "",
    serviceRequested: "",
    primaryEmergencyContactName: "",
    secondaryEmergencyContactName: "",
    primaryEmergencyContactPhoneNumber: "",
    secondaryEmergencyContactPhoneNumber: "",
    petName: "",
    petDOB: "",
    species: "",
    spayedStatus: "",
    color: "",
    vaccinationStatus: "",
    gender: "",
    breed: "",
    currentProblems: "",
    docName: "",
    hospitalName: "",
  })
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const {
    ownerFName,
    ownerLName,
    email,
    phone,
    mobilePhone,
    workPhone,
    address,
    city,
    state,
    zipCode,
    employer,
    authEmergencyDecisions,
    howHearAboutUs,
    dOBirth,
    occupation,
    authorizeEmergencyContactPatientInfo,
    serviceRequested,
    primaryEmergencyContactName,
    secondaryEmergencyContactName,
    primaryEmergencyContactPhoneNumber,
    secondaryEmergencyContactPhoneNumber,
    petName,
    petDOB,
    species,
    spayedStatus,
    color,
    vaccinationStatus,
    gender,
    breed,
    currentProblems,
    docName,
    hospitalName,
  } = values

  const [capErr, setCapErr] = useState("")
  const [ownerFNameErr, setOwnerFNameErr] = useState("")
  const [ownerLNameErr, setOwnerLNameErr] = useState("")
  const [emailErr, setEmailErr] = useState("")
  const [phoneErr, setPhoneErr] = useState("")
  const [addressErr, setAddressErr] = useState("")
  const [cityErr, setCityErr] = useState("")
  const [stateErr, setStateErr] = useState("")
  const [zipCodeErr, setZipCodeErr] = useState("")
  const [employerErr, setEmployerErr] = useState("")
  const [authEmergencyDecisionsErr, setAuthEmergencyDecisionsErr] = useState("")
  const [howHearAboutUsErr, setHowHearAboutUsErr] = useState("")
  const [dOBirthErr, setDOBirthErr] = useState("")
  const [occupationErr, setOccupationErr] = useState("")
  const [
    authorizeEmergencyContactPatientInfoErr,
    setAuthorizeEmergencyContactPatientInfoErr,
  ] = useState("")
  const [serviceRequestedErr, setServiceRequestedErr] = useState("")
  const [primaryEmergencyContactNameErr, setPrimaryEmergencyContactNameErr] =
    useState("")
  const [
    secondaryEmergencyContactNameErr,
    setSecondaryEmergencyContactNameErr,
  ] = useState("")

  const [
    primaryEmergencyContactPhoneNumberErr,
    setPrimaryEmergencyContactPhoneNumberErr,
  ] = useState("")
  const [
    secondaryEmergencyContactPhoneNumberErr,
    setSecondaryEmergencyContactPhoneNumberErr,
  ] = useState("")
  const [petNameErr, setPetNameErr] = useState("")
  const [petDOBErr, setPetDOBErr] = useState("")
  const [speciesErr, setSpeciesErr] = useState("")
  const [spayedStatusErr, setSpayedStatusErr] = useState("")
  const [vaccinationStatusErr, setVaccinationStatusErr] = useState("")
  const [genderErr, setGenderErr] = useState("")
  const [breedErr, setBreedErr] = useState("")
  const [currentProblemsErr, setCurrentProblemsErr] = useState("")
  const [docNameErr, setDocNameErr] = useState("")

  const serviceRequestedOptions = [
    { value: "Emergency & Critical Care", label: "Emergency & Critical Care" },
    { value: "Surgery", label: "Surgery" },
    { value: "New Client/Patient Visit", label: "New Client/Patient Visit" },
    { value: "Sick Pet Exam", label: "Sick Pet Exam" },
    { value: "Annual Pet Exam", label: "Annual Pet Exam" },
    {
      value: "Other (Explain Service details in textbox below)",
      label: "Other (Explain Service details in textbox below)",
    },
  ]

  const booleanOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ]

  const petSpecies = [
    { value: "Dog", label: "Dog" },
    { value: "Cat", label: "Cat" },
  ]

  const howHearAboutUsOptions = [
    { value: "Primary Care Veterinarian", label: "Primary Care Veterinarian" },
    { value: "Internet", label: "Internet" },
    { value: "Community Event", label: "Community Event" },
    { value: "Sign", label: "Sign" },
    { value: "Printed Publication", label: "Printed Publication" },
    { value: "Friend / Family", label: "Friend / Family" },
    { value: "Other", label: "Other" },
  ]

  const vaccinationStatusOptions = [
    { value: "Current", label: "Current" },
    { value: "Pastdue", label: "Pastdue" },
    { value: "Unsure", label: "Unsure" },
  ]

  const petGenderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ]

  const spayedStatusOptions = [
    { value: "Spayed", label: "Spayed" },
    { value: "Not Yet", label: "Not Yet" },
  ]

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value })

  const onChangeHowHearAboutUs = selectedOption => {
    setValues({ ...values, howHearAboutUs: selectedOption })
  }
  const onChangeIsAuthorizeEmergencyContactPatientInfo = selectedOption => {
    setValues({
      ...values,
      authorizeEmergencyContactPatientInfo: selectedOption,
    })
  }
  const onChangeServiceRequested = selectedOption => {
    setValues({ ...values, serviceRequested: selectedOption })
  }
  const onChangeSpecies = selectedOption => {
    setValues({ ...values, species: selectedOption })
  }
  const onChangeSpayedStatus = selectedOption => {
    setValues({ ...values, spayedStatus: selectedOption })
  }
  const onChangeVaccinationStatus = selectedOption => {
    setValues({ ...values, vaccinationStatus: selectedOption })
  }
  const onChangeGender = selectedOption => {
    setValues({ ...values, gender: selectedOption })
  }
  const onChangeAuth = selectedOption => {
    setValues({ ...values, authEmergencyDecisions: selectedOption })
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const submitForm = async () => {
    setCapErr("")
    setOwnerFNameErr("")
    setOwnerLNameErr("")
    setEmailErr("")
    setPhoneErr("")
    setAddressErr("")
    setCityErr("")
    setStateErr("")
    setZipCodeErr("")
    setEmployerErr("")
    setAuthEmergencyDecisionsErr("")
    setHowHearAboutUsErr("")
    setDOBirthErr("")
    setOccupationErr("")
    setAuthorizeEmergencyContactPatientInfoErr("")
    setServiceRequestedErr("")
    setPrimaryEmergencyContactNameErr("")
    setSecondaryEmergencyContactNameErr("")
    setPrimaryEmergencyContactPhoneNumberErr("")
    setSecondaryEmergencyContactPhoneNumberErr("")
    setPetNameErr("")
    setPetDOBErr("")
    setSpeciesErr("")
    setSpayedStatusErr("")
    setVaccinationStatusErr("")
    setGenderErr("")
    setBreedErr("")
    setCurrentProblemsErr("")
    setDocNameErr("")

    setSuccess(false)

    if (!capVal) {
      setCapErr("Are you a robot?")
    }
    if (!ownerFName) {
      setOwnerFNameErr("First Name is required")
    }
    if (!ownerLNameErr) {
      setOwnerLNameErr("Last Name is required")
    }
    if (!email) {
      setEmailErr("Email is required")
    }
    if (!phone) {
      setPhoneErr("Phone Number is required")
    }
    if (!address) {
      setAddressErr("Address is required")
    }
    if (!city) {
      setCityErr("Patient Info is required")
    }
    if (!state) {
      setStateErr("Preferred Contact Method is required")
    }
    if (!zipCode) {
      setZipCodeErr("Zip Code is required")
    }
    if (!employer) {
      setEmployerErr("Preferred Contact Method is required")
    }
    if (!authEmergencyDecisions) {
      setAuthEmergencyDecisionsErr(
        "Authorizing Your Emergency Contact is required"
      )
    }
    if (!howHearAboutUs) {
      setHowHearAboutUsErr("How you heard about us is required")
    }
    if (!dOBirth) {
      setDOBirthErr("Date of Birth is required")
    }
    if (!occupation) {
      setOccupationErr("Occupation is required")
    }

    if (!authorizeEmergencyContactPatientInfo) {
      setAuthorizeEmergencyContactPatientInfoErr(
        "Authorizing Emergency Contact is required"
      )
    }

    if (!serviceRequested) {
      setServiceRequestedErr("The service requested is required")
    }
    if (!primaryEmergencyContactName) {
      setPrimaryEmergencyContactNameErr(
        "Primary Emergency Contact Name is Required"
      )
    }
    if (!secondaryEmergencyContactName) {
      setSecondaryEmergencyContactNameErr(
        "Secondary Emergency Contact Name is required"
      )
    }
    if (!primaryEmergencyContactPhoneNumber) {
      setPrimaryEmergencyContactPhoneNumberErr(
        "Primary Emergency Contact Number is required"
      )
    }
    if (!secondaryEmergencyContactPhoneNumber) {
      setSecondaryEmergencyContactPhoneNumberErr(
        "Secondary Emergency Contact Number is required"
      )
    }
    if (!petName) {
      setPetNameErr("Pet Name is required")
    }
    if (!petDOB) {
      setPetDOBErr("Pet Date of Birth is required")
    }
    if (!species) {
      setSpeciesErr("Species is required")
    }
    if (!spayedStatus) {
      setSpayedStatusErr("Spayed Status is required")
    }
    if (!vaccinationStatus) {
      setVaccinationStatusErr("Vaccination Status is required")
    }
    if (!gender) {
      setGenderErr("Gender is required")
    }

    if (!breed) {
      setBreedErr("Breed is required")
    }
    if (!currentProblems) {
      setCurrentProblemsErr("Presenting Problem is required")
    }
    if (!docName) {
      setDocNameErr("Doctor Name is required")
    }
    if (!validateEmail(email)) {
      setEmailErr("Please provide a valid email")
    }
    if (
      !capVal ||
      !ownerFName ||
      !ownerLName ||
      !email ||
      !phone ||
      !address ||
      !city ||
      !state ||
      !zipCode ||
      !employer ||
      !authEmergencyDecisions ||
      !howHearAboutUs.value ||
      !dOBirth ||
      !occupation ||
      !authorizeEmergencyContactPatientInfo.value ||
      !serviceRequested ||
      !primaryEmergencyContactName ||
      !secondaryEmergencyContactName ||
      !primaryEmergencyContactPhoneNumber ||
      !secondaryEmergencyContactPhoneNumberErr ||
      !petName ||
      !petDOB ||
      !species ||
      !spayedStatus ||
      !vaccinationStatus ||
      !gender ||
      !breed ||
      !currentProblems ||
      !docName
    ) {
      return
    }
    try {
      const res = await fetch("/.netlify/functions/newPatient", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      if (res.ok) {
        setSuccess(true)
      }
    } catch (err) {
      console.log(err)
      setError("Something went wrong")
    }
  }

  return (
    <section className="referral-form-sec contact-form-sec">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Owner Information</h2>
          </div>
          <div className="col">
            <div className="row">
              <div className="col col-md-6 referral-form-sec-inner">
                <label>
                  <input
                    type="text"
                    name="ownerFName"
                    placeholder="Owner's First Name *"
                    value={ownerFName}
                    onChange={handleChange}
                  />
                  {ownerFNameErr && <p>{ownerFNameErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="ownerLName"
                    placeholder="Owner Last Name *"
                    value={ownerLName}
                    onChange={handleChange}
                  />
                  {ownerLNameErr && <p>{ownerLNameErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email*"
                    value={email}
                    onChange={handleChange}
                  />
                  {emailErr && <p>{emailErr}</p>}
                </label>
                <label>
                  <input
                    type="phone"
                    name="phone"
                    placeholder="Primary Phone Number *"
                    value={phone}
                    onChange={handleChange}
                  />
                  {phoneErr && <p>{phoneErr}</p>}
                </label>

                <label>
                  <input
                    type="phone"
                    name="mobilePhone"
                    placeholder="Mobile Phone"
                    value={mobilePhone}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  <input
                    type="phone"
                    name="workPhone"
                    placeholder="Work Phone"
                    value={workPhone}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Address *"
                    value={address}
                    onChange={handleChange}
                  />
                  {addressErr && <p>{addressErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="city"
                    placeholder="City *"
                    value={city}
                    onChange={handleChange}
                  />
                  {cityErr && <p>{cityErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="state"
                    placeholder="State *"
                    value={state}
                    onChange={handleChange}
                  />
                  {stateErr && <p>{stateErr}</p>}
                </label>
              </div>
              <ReferralLinks data={data} />
            </div>
          </div>
        </div>
      </div>
      <div className="container referral-form-con">
        <div className="row">
          <div className="col">
            <h2>
              Owner Information <small>(Continued)</small>
            </h2>
          </div>
          <div className="col">
            <div className="row">
              <div className="col col-md-6 referral-form-sec-inner">
                <label>
                  <input
                    type="text"
                    name="zipCode"
                    placeholder="Zip Code *"
                    value={zipCode}
                    onChange={handleChange}
                  />
                  {zipCodeErr && <p>{zipCodeErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="employer"
                    placeholder="Employer *"
                    value={employer}
                    onChange={handleChange}
                  />
                  {employerErr && <p>{employerErr}</p>}
                </label>
                <label>
                  <Select
                    options={booleanOptions}
                    className={`customDropDown zIndexFixHigh`}
                    classNamePrefix={`customDropDown`}
                    required
                    styles={customStyles}
                    placeholder={`Do you authorize your emergency contact(s) to make urgent treatment decisions if you are unreachable? *`}
                    name="authEmergencyDecisions"
                    value={authEmergencyDecisions}
                    onChange={onChangeAuth}
                  />
                  {authEmergencyDecisionsErr && (
                    <p>{authEmergencyDecisionsErr}</p>
                  )}
                </label>
                <label>
                  <Select
                    options={howHearAboutUsOptions}
                    className={`customDropDown zIndexFix`}
                    classNamePrefix={`customDropDown`}
                    required
                    styles={customStyles}
                    placeholder={`How did you hear about us? *`}
                    name="howHearAboutUs"
                    value={howHearAboutUs}
                    onChange={onChangeHowHearAboutUs}
                  />
                  {howHearAboutUsErr && <p>{howHearAboutUsErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="dOBirth"
                    placeholder="Date of Birth *"
                    value={dOBirth}
                    onChange={handleChange}
                  />
                  {dOBirthErr && <p>{dOBirthErr}</p>}
                </label>
              </div>
              <div className="col col-md-6 referral-form-sec-inner referral-form-sec-inner1">
                <label>
                  <input
                    type="text"
                    name="secondaryEmergencyContactName"
                    placeholder="Secondary Emergency Contact Phone *"
                    value={secondaryEmergencyContactName}
                    onChange={handleChange}
                  />
                  {secondaryEmergencyContactNameErr && (
                    <p>{secondaryEmergencyContactNameErr}</p>
                  )}
                </label>
                <label>
                  <input
                    type="text"
                    name="occupation"
                    placeholder="Occupation *"
                    value={occupation}
                    onChange={handleChange}
                  />
                  {occupationErr && <p>{occupationErr}</p>}
                </label>
                <label>
                  <Select
                    options={booleanOptions}
                    className={`customDropDown zIndexFixHigh`}
                    classNamePrefix={`customDropDown`}
                    required
                    styles={customStyles}
                    placeholder={`Do you authorize your emergency contact(s) to receive patient information?  *`}
                    name="authorizeEmergencyContactPatientInfo"
                    value={authorizeEmergencyContactPatientInfo}
                    onChange={onChangeIsAuthorizeEmergencyContactPatientInfo}
                  />
                  {authorizeEmergencyContactPatientInfoErr && (
                    <p>{authorizeEmergencyContactPatientInfoErr}</p>
                  )}
                </label>
                <label>
                  <Select
                    options={serviceRequestedOptions}
                    className={`customDropDown zIndexFix`}
                    classNamePrefix={`customDropDown`}
                    required
                    styles={customStyles}
                    placeholder={`Service Requested *`}
                    name="serviceRequested"
                    value={serviceRequested}
                    onChange={onChangeServiceRequested}
                  />
                  {serviceRequestedErr && <p>{serviceRequestedErr}</p>}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container referral-form-con">
        <div className="row">
          <div className="col">
            <h2>Emergency Contact</h2>
          </div>
          <div className="col">
            <div className="row">
              <div className="col col-md-6 referral-form-sec-inner">
                <label>
                  <input
                    type="text"
                    name="primaryEmergencyContactName"
                    placeholder="Primary Emergency Contact Name *"
                    value={primaryEmergencyContactName}
                    onChange={handleChange}
                  />
                  {primaryEmergencyContactNameErr && (
                    <p>{primaryEmergencyContactNameErr}</p>
                  )}
                </label>
                <label>
                  <input
                    type="text"
                    name="secondaryEmergencyContactName"
                    placeholder="Secondary Emergency Contact Name *"
                    value={secondaryEmergencyContactName}
                    onChange={handleChange}
                  />
                  {secondaryEmergencyContactNameErr && (
                    <p>{secondaryEmergencyContactNameErr}</p>
                  )}
                </label>
              </div>
              <div className="col col-md-6 referral-form-sec-inner">
                <label>
                  <input
                    type="text"
                    name="primaryEmergencyContactPhoneNumber"
                    placeholder="Primary Emergency Contact Phone Number *"
                    value={primaryEmergencyContactPhoneNumber}
                    onChange={handleChange}
                  />
                  {primaryEmergencyContactPhoneNumberErr && (
                    <p>{primaryEmergencyContactPhoneNumberErr}</p>
                  )}
                </label>
                <label>
                  <input
                    type="text"
                    name="secondaryEmergencyContactPhoneNumber"
                    placeholder="Secondary Emergency Contact Number *"
                    value={secondaryEmergencyContactPhoneNumber}
                    onChange={handleChange}
                  />
                  {secondaryEmergencyContactPhoneNumberErr && (
                    <p>{secondaryEmergencyContactPhoneNumberErr}</p>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container referral-form-con">
        <div className="row">
          <div className="col">
            <h2>Patient Information</h2>
          </div>
          <div className="col">
            <div className="row">
              <div className="col col-md-6 referral-form-sec-inner">
                <label>
                  <input
                    type="text"
                    name="petName"
                    placeholder="Pet Name *"
                    value={petName}
                    onChange={handleChange}
                  />
                  {petNameErr && <p>{petNameErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="petDOB"
                    placeholder="Birth Date / Age *"
                    value={petDOB}
                    onChange={handleChange}
                  />
                  {petDOBErr && <p>{petDOBErr}</p>}
                </label>
                <div className="referral-form-half">
                  <label>
                    <Select
                      options={petSpecies}
                      className={`customDropDown zIndexFixHigh`}
                      classNamePrefix={`customDropDown`}
                      required
                      styles={customStyles}
                      placeholder={`Species *`}
                      name="species"
                      value={species}
                      onChange={onChangeSpecies}
                    />
                    {speciesErr && <p>{speciesErr}</p>}
                  </label>
                </div>
                <div className="referral-form-half">
                  <label>
                    <Select
                      options={spayedStatusOptions}
                      className={`customDropDown zIndexFix`}
                      classNamePrefix={`customDropDown`}
                      required
                      styles={customStyles}
                      placeholder={`Spayed or Neutered *`}
                      name="spayedStatus"
                      value={spayedStatus}
                      onChange={onChangeSpayedStatus}
                    />
                    {spayedStatusErr && <p>{spayedStatusErr}</p>}
                  </label>
                </div>
              </div>
              <div className="col col-md-6 referral-form-sec-inner referral-form-sec-inner1">
                <label>
                  <input
                    type="text"
                    name="color"
                    placeholder="Color"
                    value={color}
                    onChange={handleChange}
                  />
                </label>

                <label>
                  <Select
                    options={vaccinationStatusOptions}
                    className={`customDropDown zIndexFixHigh`}
                    classNamePrefix={`customDropDown`}
                    required
                    styles={customStyles}
                    placeholder={`Vaccination Status *`}
                    name="vaccinationStatus"
                    value={vaccinationStatus}
                    onChange={onChangeVaccinationStatus}
                  />
                  {vaccinationStatusErr && <p>{vaccinationStatusErr}</p>}
                </label>
                <div className="referral-form-half">
                  <label>
                    <Select
                      options={petGenderOptions}
                      className={`customDropDown zIndexFix`}
                      classNamePrefix={`customDropDown`}
                      required
                      styles={customStyles}
                      placeholder={`Gender *`}
                      name="gender"
                      value={gender}
                      onChange={onChangeGender}
                    />
                    {genderErr && <p>{genderErr}</p>}
                  </label>
                </div>
                <label>
                  <input
                    type="text"
                    name="breed"
                    placeholder="Breed / Other Species *"
                    value={breed}
                    onChange={handleChange}
                  />
                  {breedErr && <p>{breedErr}</p>}
                </label>
              </div>
              <div className="col referral-form-sec-inner">
                <label>
                  <textarea
                    placeholder="Presenting problem and concerns *"
                    name="currentProblems"
                    value={currentProblems}
                    onChange={handleChange}
                  ></textarea>
                  {currentProblemsErr && <p>{currentProblemsErr}</p>}
                </label>
                <h2>Primary Veterinarian Information</h2>
                <p>
                  Please fill out this section if Canton Animal Hospital is not
                  your primary care veterinary hospital.
                </p>
                <label>
                  <input
                    type="text"
                    name="docName"
                    placeholder="Doctor Name*"
                    value={docName}
                    onChange={handleChange}
                  />
                  {docNameErr && <p>{docNameErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="hospitalName"
                    placeholder="Hospital Name"
                    value={hospitalName}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  <ReCAPTCHA
                    sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                    onChange={val => setCapVal(val)}
                  />
                  {capErr && <p>{capErr}</p>}
                </label>
                <div className="referral-form-group checkboxFormValid">
                  <ul className="justify-content-center d-flex">
                    <li>
                      <label>
                        <input type="checkbox" name="radio1" />
                        <span></span>
                        <p>
                          By submitting this form, I hereby authorize Canton
                          Animal Hospital to render medical care for my pet(s)
                          as deemed necessary by the veterinarian. I understand
                          that no guarantee can be given to the outcome of
                          treatments and take it as my responsibility to
                          appropriately comprehend any risks involved. I agree
                          to pay for the cost of all services to which I consent
                          to by written or verbal estimate. I understand that a
                          deposit is required before diagnostics and treatments
                          can be initiated and that payment in full is required
                          prior to patient discharge from Canton Animal
                          Hospital.
                        </p>
                      </label>
                    </li>
                  </ul>
                </div>

                <div className="referral-link-btn text-center">
                  <button type="button" className="d-btn" onClick={submitForm}>
                    Submit
                  </button>
                  {error && <p>{error}</p>}
                  {success && (
                    <p>
                      Thank you for contacting us! We'll be in touch with you
                      soon.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReferralForm
